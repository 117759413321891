
export default class ClaimForm {
    constructor() {
        this.claimType = null;
        this.vinNumberSection = null;
        this.orderNumberSection = null;
        this.deliveryDateSection = null;
        this.orderDateSection = null;

    }

    init() {
        this.claimType = document.querySelector('[data-claim-type-toggle]');
        this.vinNumberSection = document.getElementById('vin-number-section');
        this.orderNumberSection = document.getElementById('order-number-section');
        this.deliveryDateSection = document.getElementById('delivery-date-section');
        this.orderDateSection = document.getElementById('order-date-section');

        this.initAfterFrameworks();

        if (tps.datepicker)
            tps.datepicker.init();
    }

    setSubmitted() {

        const submitBtn = document.getElementById('spiffs-claim-form-submit');
        submitBtn.classList.add('loading');

    }

    initAfterFrameworks() {
        const _self = tps.claimForm;

        if (_self.claimType) {
            _self.claimType.addEventListener('change', function (e) {
                _self.toggleFields();
            });
        }
    }

    toggleFields() {
        const _self = tps.claimForm;

        if (_self.claimType) {
            if (_self.claimType.options[_self.claimType.selectedIndex].text == "Polestar 3 Order")
            {
                _self.vinNumberSection.classList.add('hide');
                _self.orderNumberSection.classList.remove('hide');

                _self.deliveryDateSection.classList.add('hide');
                _self.orderDateSection.classList.remove('hide');

            }
            else
            {
                _self.vinNumberSection.classList.remove('hide');
                _self.orderNumberSection.classList.add('hide');

                _self.deliveryDateSection.classList.remove('hide');
                _self.orderDateSection.classList.add('hide');
            }
        }
    }

}
