export default class Featurette {
    constructor() {
        this.items = document.querySelectorAll('.tps-featurette-main-text, .tps-featurette-item');
        this.lastKnownScrollPosition = 0;
        this.ticking = false;
    }

    init() {

        const _self = tps.featurette;

        if (_self.items.length > 0) {
            _self.initEvents();
        }

    }


    // initAfterFrameworks() {}

    initEvents() {
        const _self = tps.featurette;
        // check the scroll position
        // relative to the items
        _self.checkItems();

        document.addEventListener('scroll', evt => {
            if (!_self.ticking) {
                window.requestAnimationFrame(function () {
                    _self.checkItems();
                    _self.ticking = false;
                });

                _self.ticking = true;
            }
        });

    }

    checkItems() {
        const _self = tps.featurette;

        _self.items.forEach(item => {

            if (tps.utility.isInViewport(item)) {
                item.classList.add('active')
            } 

        });

    }

}
