
export default class Utility {
    constructor() {}

    init() {

        // when a link is just a dummy link
        const dummyLinks = document.querySelectorAll('[href="#"]');
        dummyLinks.forEach(link => {
            // do nothing when it is clicked
            link.addEventListener('click', evt => {
                evt.preventDefault();
            });
        });

        this.initInputsWithFloatingLabels();

    }

    // initAfterFrameworks() {}

    getCookie(a) {
        let result = [], c = document.cookie.split(";");
        a = new RegExp("^\\s*" + a + "=\\s*(.*?)\\s*$");
        for (let d = 0; d < c.length; d++) {
            let e = c[d].match(a);
            e && result.push(e[1]);
        }
        return result[0];
    };

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };

    createElement(element, classes, id) {

        const elem = document.createElement(element);

        if (classes) {
            classes.trim().split(' ').forEach((classname) => {
                elem.classList.add(classname);
            });

        }

        if (id)
            elem.setAttribute('id', id);

        return elem;

    }

    createLinkElement(classes, tabindex, textContent, ariaLabel, href, id, target) {

        const elem = Utility.createElement('a', classes, id);

        if (tabindex)
            elem.setAttribute('tabindex', tabindex);

        if (textContent)
            elem.textContent = textContent;

        if (ariaLabel)
            elem.ariaLabel = ariaLabel;

        if (href)
            elem.setAttribute('href', href);

        if (target)
            elem.setAttribute('target', target);

        return elem;
    }

    // html decodes a string
    decodeHtml(htmlString) {

        var txt = document.createElement("textarea");
        txt.innerHTML = htmlString;
        return txt.value;

    }

    elementIsVisible(element) {

        if (!element)
            return false;


        // check if the element has dimensions
        return element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0;

    }

    isInViewport(element, buffer = 30) {
        
        if (!element)
            return false;

        let top = element.offsetTop;
        let left = element.offsetLeft;
        const width = element.offsetWidth;
        const height = element.offsetHeight;

        while (element.offsetParent) {
            element = element.offsetParent;
            top += element.offsetTop;
            left += element.offsetLeft;
        }

        return (
            (top + buffer) < (window.pageYOffset + window.innerHeight) &&
            (left + buffer) < (window.pageXOffset + window.innerWidth) &&
            (top + buffer + height) > window.pageYOffset &&
            (left + buffer + width) > window.pageXOffset
        );
    }

    initInputsWithFloatingLabels() {
        const inputs = document.querySelectorAll('.with-floating-label');

        const _toggleValueClass = (input) => {
            if (input.value !== '') {
                input.classList.add('has-value');
            } else {
                input.classList.remove('has-value');
            }
        };

        inputs.forEach(input => {
            _toggleValueClass(input);

            input.addEventListener('keyup', (e) => {
                _toggleValueClass(input);
            });
            input.addEventListener('change', (e) => {
                _toggleValueClass(input);
            });
            input.addEventListener('input', (e) => {
                _toggleValueClass(input);
            });
            input.addEventListener('animationstart', (e) => {
                _toggleValueClass(input);
            });
            input.addEventListener('transitionstart', (e) => {
                _toggleValueClass(input);
            });
        });

        
    }

}
