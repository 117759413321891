export default class CookiesModal {

    constructor() {
        this.disclaimer = document.getElementById('cookies-disclaimer');
        this.disclaimerSettingsButton = document.getElementById('cookies-disclaimer-settings');

        this.settingsModal = document.getElementById('cookies-settings-modal');
        this.settingsModalReveal = null;
        this.settingsModalSwitches = this.settingsModal.querySelectorAll('[name="tps-cookie-level"]');
        this.settingsModalConfirm = this.settingsModal.querySelectorAll('[data-action-cookies-confirm]');

        this.cookieActions = document.querySelectorAll('[data-action-cookies-accept]');
                    
    }

    // init() {}

    initAfterFrameworks() {
        const _self = tps.cookiesModal;

        if (!_self.disclaimer && !_self.settingsModal) {
            return;
        }

        if (_self.settingsModal) {
            _self.settingsModalReveal = $(_self.settingsModal);
        }

        if (_self.cookieActions.length > 0) {

            [..._self.cookieActions].forEach(button => {
                button.addEventListener('click', function (e) {
                    let _cookieLevel = parseInt(e.currentTarget.dataset.actionCookiesAccept) ? parseInt(e.currentTarget.dataset.actionCookiesAccept) : 1000;
                    _self.updateCookieLevelSwitches(_cookieLevel, true);
                    _self.updateCookieLevel(_cookieLevel);
                });
            });
        }

        if (_self.disclaimerSettingsButton) {
            _self.disclaimerSettingsButton.addEventListener('click', function (e) {
                _self.disclaimer.classList.add('hide');
                _self.settingsModalReveal.foundation('open');
                _self.settingsModalReveal.on('closed.zf.reveal', (e) => {
                    if (_self.disclaimer.dataset.enabled === 'true') {
                        _self.disclaimer.classList.remove('hide');
                    }
                });
            });
        }

        [..._self.settingsModalSwitches].forEach(_switch => {
            _switch.addEventListener('change', e => {

                const elem = e.currentTarget;
                const elemValue = parseInt(elem.value);

                _self.updateCookieLevelSwitches(elemValue, elem.checked);               

            });
        });

        if (_self.settingsModalConfirm.length > 0) {

            [..._self.settingsModalConfirm].forEach(button => {

                button.addEventListener('click', e => {

                    // start with -100 cookie level
                    let _cookieSelections = [-100];

                    // get all the checkbox values
                    [..._self.settingsModalSwitches].forEach(_switch => {

                        const value = parseInt(_switch.value);
                        // append the checked values 
                        if (_switch.checked && typeof value === 'number') {
                            _cookieSelections.push(value);
                        }
                    });

                    _self.updateCookieLevel(Math.max(..._cookieSelections));

                });

            });

        }

    }

    updateCookieLevelSwitches(_value, _checked) {
        const _self = tps.cookiesModal;

        [..._self.settingsModalSwitches].forEach(__switch => {
            const __switchValue = parseInt(__switch.value);
            if (__switchValue <= _value && _checked) {
                __switch.checked = true;
            }
            else if (__switchValue > _value && !_checked) {
                __switch.checked = false;
            }
        });

    }

    updateCookieLevel(_cookieLevel) {
        const _self = tps.cookiesModal;

        const fetchUrl = new URL('/api/cookielevel/set', location.origin);

        const fetchParams = {
            cookieLevel: _cookieLevel
        };

        fetchUrl.search = new URLSearchParams(fetchParams).toString();
        fetch(fetchUrl, { method: 'POST' }).then(response => {
            if (_self.disclaimer) {
                _self.disclaimer.dataset.enabled = false;
                _self.disclaimer.classList.add('hide');
            }
            if (_self.settingsModalReveal) {
                _self.settingsModalReveal.foundation('close');
            }
        });

    }

}










