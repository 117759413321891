//import Litepicker from 'litepicker';
import { easepick, LockPlugin } from '@easepick/bundle';

export default class Datepicker {
    constructor() { }

    init() {

        const datePickers = document.querySelectorAll('.has-datepicker');


        datePickers.forEach(datePicker => {

            let minDate = new Date();
            if (datePicker.dataset.tpsMinDate)
            {
                minDate = datePicker.dataset.tpsMinDate;
            }
            // set the time of the date to be 12:00AM
            // console.log(minDate);

            const _picker = new easepick.create({
                element: datePicker,
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
                ],
                format: 'MM/DD/YYYY',
                firstDay: 0,
                plugins: [ LockPlugin ],
                readonly: false,
                LockPlugin: {
                    minDate: minDate
                },
            });

        });

    }

}
