
export default class W9Form {
    constructor() {}

    //init() {}

    initAfterFrameworks() {
        const _self = tps.W9Form;
    }

    checkW9Input(event, selector) {

        const input = event.currentTarget;
        const submitBtn = document.querySelector(selector);

        if (input.value)
        {
            submitBtn.classList.remove('button-light');
            submitBtn.disabled = false;
        }
        else
        {
            submitBtn.classList.add('button-light');
            submitBtn.disabled = true;
        }


    }

    
    submitForm(event, selector) {

        const form = document.querySelector(selector);
        const formData = new FormData(form);
        const submitBtn = event.currentTarget;
        const resultElem = document.querySelector(form.dataset.ajaxUpdate)

        submitBtn.classList.add('submitting');
        submitBtn.disabled = true;
        

        $.ajax({
            url: form.action,
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function (data) {
                if (data == true)
                    window.location.reload();
                else {
                    resultElem.innerHTML = data;
                    // call this function to reinit the file upload
                    // input behaviour
                    window.w9UploadCtrl();
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(xhr);
            }
        });

        
      

    }

}
