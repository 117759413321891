export default class Navigation {
    constructor() {
    }

    init() {

        const _self = tps.navigation;

    }

    //initAfterFrameworks() { }


}
